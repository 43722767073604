<template>
    <div>
        <b-card no-body>
            <b-card-header>
                <b-card-title>Airdrop Register</b-card-title>
            </b-card-header>
            <b-card-body>
                <b-form action="" @submit.prevent="insertQuest">
                    <b-row>
                        <b-col lg="3" md="4" sm="12">
                            <b-form-group label="Title Airdrop">
                                <b-form-input v-model="moption.title" placeholder="Title Airdrop" required />
                            </b-form-group>
                        </b-col>
                        <b-col lg="3" md="4" sm="12">
                            <b-form-group label="Category Airdrop">
                                <b-form-input v-model="moption.category" placeholder="Category Airdrop" required />
                            </b-form-group>
                        </b-col>
                        <b-col lg="3" md="4" sm="12">
                            <b-form-group label="Likehood Airdrop">
                                <b-form-input v-model="moption.airdroplikehood" placeholder="Likehood Airdrop" required />
                            </b-form-group>
                        </b-col>

                        <b-col lg="3" md="4" sm="12">
                            <b-form-group label="Image Airdrop">
                                <b-form-file v-model="imagen" placeholder="Image Airdrop"
                                    drop-placeholder="Drop file here..." @input="readURL" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12">
                            <b-form-group label="Description Airdrop">
                                <quill-editor required style="min-height:150px;" v-model="moption.description"
                                    placeholder="Description Airdrop" :options="editorOption">
                                    <div id="toolbar" slot="toolbar">
                                        <button class="ql-bold">Bold
                                        </button>
                                        <button class="ql-italic">
                                            Italic
                                        </button>
                                        <button class="ql-underline">
                                            underline
                                        </button>
                                        <span class="ql-formats">
                                            <button class="ql-align" value=""></button>
                                            <button class="ql-align" value="center"></button>
                                            <button class="ql-align" value="right"></button>
                                            <button class="ql-align" value="justify"></button>
                                        </span>
                                        <button class="ql-header" value="1">
                                            H1
                                        </button>
                                        <button class="ql-header" value="2">
                                            H2
                                        </button>
                                        <button class="ql-list" value="ordered" />
                                        <button class="ql-list" value="bullet" />
                                        <button class="ql-indent" value="-1" />
                                        <button class="ql-indent" value="+1" />
                                        <button class="ql-emoji">
                                            emoji
                                        </button>
                                    </div>
                                </quill-editor>

                            </b-form-group>
                        </b-col>

                        <b-col cols="12">
                            <b-form-group label="Blog Airdrop">
                                <quill-editor required style="min-height:150px;" v-model="moption.blog"
                                    placeholder="Blog Airdrop" :options="editorOptionTwo">
                                    <div id="toolbar2" slot="toolbar">
                                        <button class="ql-bold">Bold
                                        </button>
                                        <button class="ql-italic">
                                            Italic
                                        </button>
                                        <button class="ql-underline">
                                            underline
                                        </button>
                                        <span class="ql-formats">
                                            <button class="ql-align" value=""></button>
                                            <button class="ql-align" value="center"></button>
                                            <button class="ql-align" value="right"></button>
                                            <button class="ql-align" value="justify"></button>
                                        </span>
                                        <button class="ql-header" value="1">
                                            H1
                                        </button>
                                        <button class="ql-header" value="2">
                                            H2
                                        </button>
                                        <button class="ql-list" value="ordered" />
                                        <button class="ql-list" value="bullet" />
                                        <button class="ql-indent" value="-1" />
                                        <button class="ql-indent" value="+1" />
                                        <button class="ql-emoji">
                                            emoji
                                        </button>
                                    </div>
                                </quill-editor>
                            </b-form-group>
                        </b-col>

                        <b-col lg="4" md="3" sm="12">
                            <b-form-group label="Website Airdrop">
                                <b-form-input v-model="moption.website" placeholder="Website Airdrop" required />
                            </b-form-group>
                        </b-col>

                        <b-col lg="4" md="3" sm="12">
                            <b-form-group label="Discord Airdrop">
                                <b-form-input v-model="moption.discord" placeholder="Discord Airdrop" required />
                            </b-form-group>
                        </b-col>

                        <b-col lg="4" md="3" sm="12">
                            <b-form-group label="Twitter Airdrop">
                                <b-form-input v-model="moption.twitter" placeholder="Twitter Airdrop" required />
                            </b-form-group>
                        </b-col>
                        <b-col md="4" sm="12">
                            <b-form-group :label="$t('questregister.startdate')">
                                <flat-pickr v-model="moption.startDate" class="form-control" required
                                    :placeholder="$t('questregister.startdate')"
                                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }" />
                            </b-form-group>
                        </b-col>
                        <b-col md="4" sm="12">
                            <b-form-group :label="$t('questregister.duedate')">
                                <flat-pickr v-model="moption.dueDate" class="form-control"
                                    :placeholder="$t('questregister.duedate')" required
                                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }" />
                            </b-form-group>
                        </b-col>
                        <b-col md="4" sm="12">
                            <b-form-group label="Logo Airdrop">
                                <b-form-file v-model="logo" placeholder="Logo Airdrop" drop-placeholder="Drop file here..."
                                    @input="readURLLogo" />
                            </b-form-group>
                        </b-col>
                        <!-- submit and reset -->

                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1">
                            <div class="flex flex-row gap-1 items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    v-if="saving">
                                    <path fill="currentColor"
                                        d="M12 2A10 10 0 1 0 22 12A10 10 0 0 0 12 2Zm0 18a8 8 0 1 1 8-8A8 8 0 0 1 12 20Z"
                                        opacity=".5" />
                                    <path fill="currentColor" d="M20 12h2A10 10 0 0 0 12 2V4A8 8 0 0 1 20 12Z">
                                        <animateTransform attributeName="transform" dur="1s" from="0 12 12"
                                            repeatCount="indefinite" to="360 12 12" type="rotate" />
                                    </path>
                                </svg>
                                <div class="text-white font-bold text-base"> {{ update ? 'Update' : 'Save' }}</div>
                            </div>
                        </b-button>
                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary">
                            {{ $t('questregister.reset') }}
                        </b-button>

                    </b-row>
                </b-form>
            </b-card-body>
        </b-card>

        <b-card no-body class="border mt-5">
            <b-card-header class="p-1">
                <b-card-title>Aridrops List</b-card-title>
            </b-card-header>
            <b-table :items="quests" :busy="busy" :fields="fields" responsive hover selectable select-mode="single"
                @row-clicked="updatebyID">
                <template #table-busy>
                    <div class="text-center text-success my-2">
                        <b-spinner class="align-middle"></b-spinner>
                    </div>
                </template>
                <template #cell(options.description)="data">
                    <div v-html="data.value"></div>
                </template>
            </b-table>
        </b-card>

    </div>
</template>
<script>
import {
    BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BCard, BCardHeader, BCardTitle, BCardBody, BFormFile,
    BFormTextarea, BFormDatepicker, BTable, BSpinner
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import SellerService from '@/services/SellerService'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import "quill-emoji/dist/quill-emoji.css";
import Quill from 'quill'
import * as Emoji from "quill-emoji";
Quill.register("modules/emoji", Emoji);
import { quillEditor } from 'vue-quill-editor'
import flatPickr from 'vue-flatpickr-component'
export default {
    components: {
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BButton, BCard, BCardHeader, BCardTitle, BCardBody, BFormFile,
        BFormTextarea, BFormDatepicker, vSelect, flatPickr, BTable, BSpinner,
        quillEditor
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            moption: {
                "title": "",
                "description": "",
                "website": "",
                "discord": "",
                "twitter": "",
                "category": "",
                "airdroplikehood": "",
                "blog": "",
                "startDate": null,
                "dueDate": null,
                "status": true,
                "titleTab": { title: 'Featured' },

            },
            editorOption: {
                modules: {
                    toolbar: '#toolbar',
                    "emoji-toolbar": true,
                    "emoji-textarea": true,
                    "emoji-shortname": true,
                }
            },
            editorOptionTwo: {
                modules: {
                    toolbar: '#toolbar2',
                    "emoji-toolbar": true,
                    "emoji-textarea": true,
                    "emoji-shortname": true,
                }
            },
            imagen: null,
            imagenFile: null,
            logo: null,
            logoFile: null,
            option: [{ title: 'Featured' }],
            dialogHidden: true,
            quests: [],
            fields: [{ label: "Airdrop Title", key: "options.title" },
            { label: "Airdrop Description", key: "options.description" },
            { label: "Airdrop Category", key: "options.category" },
            { label: "Airdrop Likehood", key: "options.airdroplikehood" }],
            busy: true,
            update: false,
            idairdop: null,
            saving: false

        }
    },
    mounted() {
        this.getQuests()
    },
    methods: {
        getQuests: async function () {
            const qu = (await SellerService.getQuests(JSON.parse(localStorage.getItem("auth")).discordId, 0)).data
            this.quests = qu
            console.log(this.quests);
            this.quests.sort((a, b) => {
                const dateA = new Date(a.options.dueDate);
                const dateB = new Date(b.options.dueDate);
                return dateB - dateA;
            });

            this.busy = false
        },
        updatebyID: function (q) {
            this.moption = q.options
            this.idairdop = q._id
            this.update = true
        },
        toogleDialogEmoji() {
            this.dialogHidden = !this.dialogHidden;
        },
        onSelectEmoji(dataEmoji) {

            this.moption.description += dataEmoji.data;
            this.toogleDialogEmoji();
        },
        readURL: function (input) {
            const vm = this;
            console.log(input);
            if (input) {
                var reader = new FileReader();
                reader.readAsDataURL(input);
                reader.onload = function (e) {
                    vm.imagen = e.target.result;
                    vm.imagenFile = input;
                };
            }
        },
        readURLLogo: function (input) {
            const vm = this;
            console.log(input);
            if (input) {
                var reader = new FileReader();
                reader.readAsDataURL(input);
                reader.onload = function (e) {
                    vm.logo = e.target.result;
                    vm.logoFile = input;
                };
            }
        },
        insertQuest: async function () {
            this.saving = true
            if (this.update) {
                const insertar = (await SellerService.updateAirdropbyID(JSON.parse(localStorage.getItem("auth")).discordId, {
                    "_id": this.idairdop, "options": this.moption
                }))
            }
            else {
                const insertar = (await SellerService.insertQuest(JSON.parse(localStorage.getItem("auth")).discordId,
                    this.moption, this.imagenFile, this.logoFile))
            }

            this.saving = false

            this.$swal({
                title: "",
                text: this.update ? "Airdrop Updated Successfull" : "Airdrop Saved Successfull",
                icon: "success",
                allowOutsideClick: false,
                showClass: {
                    popup: "animate__animated animate__fadeIn",
                },
                customClass: {
                    confirmButton: "btn btn-success",
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    this.$router.go(0)
                }
            });
        }
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "@core/scss/vue/libs/vue-sweetalert.scss";
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.ql-container,
.ql-editor {
    min-height: inherit;
}
</style>